import React from 'react'
import Banner from '../../banner'

import SectionOne from './SectionOne'
//import Testimonial from '../Home/Testimonial'


const TestimonialsPage = () => (
    <>
    <Banner />
    <SectionOne />
    </>
)

export default TestimonialsPage